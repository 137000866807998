import React, { Component } from 'react';
import axios from 'axios';
import TopAppBar, { TopAppBarFixedAdjust } from '@material/react-top-app-bar';
import TextField, { Input } from '@material/react-text-field';
import MaterialIcon from '@material/react-material-icon';

import './Home.scss';
import List from './List';
import Grid from './Grid';

export class Home extends Component {
  constructor(props) {
    super(props);

    const { hostname, port, protocol, search } = window.location;
    const searchParams = new URLSearchParams(search);
    const value = searchParams.has('query') ? searchParams.get('query'): '';
    this.state = {
      booksURL: `${protocol}//${hostname}:${port}/books`,
      books: [],
      selected: '',
      isList: false,
      sort: 'name',
      value,
    };
  }

  componentDidMount() {
    document.body.addEventListener('click', this.clearSelected);

    axios.get(this.state.booksURL)
      .then((response) => {
        this.setState({ books: response.data });
      });
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.clearSelected);
  }

  setSelected = (selected) => {
    this.setState({ selected });
  };

  clearSelected = (e) => {
    if (e.target.closest('div.Book') === null) {
      this.setState({ selected: '' });
    }
  };

  toggleMode = () => {
    let { sort, books } = this.state;
    const nameAscending = (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    const timestampDescending = (a, b) => a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 1 : 0;
    const publicationDescending = (a, b) => a.publication > b.publication ? -1 : a.publication < b.publication ? 1 : 0;
    if (sort === 'name') {
      sort = 'timestamp';
      books = books.sort(timestampDescending);
    } else if (sort === 'timestamp'){
      sort = 'publication';
      books = books.sort(publicationDescending);
    } else if (sort === 'publication') {
      sort = 'name';
      books = books.sort(nameAscending);
    }
    this.setState({ sort, books });
  };

  filterBooks = book => book.author.toLowerCase().includes(this.state.value.toLowerCase()) ||
    book.title.toLowerCase().includes(this.state.value.toLowerCase()) || (
      book.subjects && book.subjects.length > 0 && book.subjects.some(subject => subject.toLowerCase().includes(this.state.value.toLowerCase())));

  onChange = (e) => {
    const query = e.currentTarget.value;
    const url = new URL(window.location.href);

    if (query) {
      url.searchParams.set('query', query);
    } else if (url.searchParams.has('query')) {
      url.searchParams.delete('query');
    }

    url.search = url.searchParams
    window.history.pushState(null, null, url);

    this.setState({value: e.currentTarget.value});
  }

  render() {
    if (this.state.books.length === 0) return null;

    const filtered = this.state.books.filter(this.filterBooks);

    const books = this.state.isList ? <List books={filtered} booksURL={this.state.booksURL} /> : <Grid books={filtered} booksURL={this.state.booksURL} selected={this.state.selected} setSelected={this.setSelected} />;

    return (
      <div className="App">
        <TopAppBar>
          <TextField
            fullWidth={true}
            onTrailingIconSelect={() => this.toggleMode()}
            trailingIcon={<MaterialIcon icon={'sort'} />}
          >
            <Input
              value={this.state.value}
              placeholder="Search by author, title or genre"
              autoFocus
              onChange={this.onChange} />
          </TextField>
        </TopAppBar>
        <TopAppBarFixedAdjust>
          { books }
        </TopAppBarFixedAdjust>
      </div>
    );
  }
}
