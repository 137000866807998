/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.scss';

const { hash } = window.location;
const token = hash.slice(1);
if (token) Cookies.set('id', token, { expires: 365 });

if (Cookies.get('id')) {
  ReactDOM.render(
    <BrowserRouter><App /></BrowserRouter>,
    document.getElementById('root'),
  );
}
