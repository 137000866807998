import React from 'react';
import Book from './Book';

const Grid = (props) => {
  const isIOS = /iPad|iPhone|iPod/.test(window.navigator.platform);
    const books = props.books.map((book, index) => (
        <Book
          key={index}
          id={index}
          selected={index === props.selected}
          setSelected={props.setSelected}
          name={book.name}
          title={book.title}
          author={book.author}
          cover={encodeURI(`${props.booksURL}/${book.name}.${(isIOS) ? 'jpg' : 'webp'}`)}
          epub={encodeURI(`${props.booksURL}/${book.name}.epub`)}
        />));

    return (
        <div className="grid-wrapper">
          { books }
        </div>
    );
};

export default Grid;
