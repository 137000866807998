import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Home } from './Home';
import Description from './Description';

const App = () => (
  <div className="App">
    <Switch>
      <Route path="/:name">
        <Description />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </div>
);

export default App;
