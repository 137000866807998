import React, { Component } from 'react';
import axios from 'axios';

import './Description.scss';

class Description extends Component {
  state = {
    description: ''
  }

  componentDidMount() {
    axios.get('/books' + window.location.pathname + '/description')
      .then((response) => {
        this.setState({ description: response.data });
      })
      .catch(() => console.error('unable to fetch description'));
  }

  render() {
    return (
      <div className="Description">
        <span dangerouslySetInnerHTML={{ __html: this.state.description }} />
      </div>
    );
  }
}

export default Description;
