import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

import './Book.scss';

class Book extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.selected) {
      this.props.setSelected('');
    } else {
      this.props.setSelected(this.props.id);
    }
  }

  render() {
    let bookClassName = 'Book';
    if (this.props.selected) {
      bookClassName = bookClassName.concat(' selected');
    }

    return (
      <div className={bookClassName} title={this.props.name} onClick={this.onClick}>
        <div className="book-overlay" />
        <LazyLoad height={250} once={true} offset={500}>
          <img src={this.props.cover} alt="book" height="245" width="160" />
        </LazyLoad>
        <div className="book-details">
          <span className="book-title">{this.props.title}</span>
          <span className="fade-title" />
          <span className="book-author">{this.props.author}</span>
        </div>
        <div className="link">
          <Link to={encodeURI(`/${this.props.name}`)}>
            <span className="description-link" />
          </Link>
          <a href={this.props.epub}>
            <span className="download-link" />
          </a>
        </div>
      </div>
    );
  }
}

export default Book;
