import React from 'react';
import './List.scss';

const List = (props) => {
    const listBooks = props.books.map((book, index) => (
        <li className="mdc-list-item" tabIndex="0" key={index}>
          <span className="mdc-list-item__text">
            <span className="mdc-list-item__primary-text">{book.title}</span>
            <span className="mdc-list-item__secondary-text">{book.author}</span>
          </span>
          <span className="mdc-list-item__meta">
            <a href={encodeURI(`${props.booksURL}/${book.name}.epub`)}>
              <span className="list-download-icon mdc-fab__icon material-icons">save_alt</span>
            </a>
          </span>
        </li>
    ));

    return (
        <div className="list-wrapper">
          <ul className="mdc-list mdc-list--two-line mdc-list--dense">
            {listBooks}
          </ul>
        </div>
    );
};

export default List;
